import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AlertContext } from "../../store/AlertContext";
import { EventsContext } from "../../store/EventsContext";
import { MemberContext } from "../../store/MemberContext";
import { fetchEvents } from "../../utils/http";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Grow from "@mui/material/Grow";
import ViewContainer from "../../components/ViewContainer";
import ViewTabs from "../../components/ViewTabs";
import EventCard from "../../components/EventCard";
import ConferenceDialog from "../../components/ConferenceDialog";

const tabs = [ { label: 'tabs.upcoming', index: 0 }, { label: 'tabs.past', index: 1 } ];

export default function Events() {
  const [tab, selectTab] = useState(0);
  const [conferenceDialogOpen, setConferenceDialogOpen] = useState(false);
  const [conferenceUrl, setConferenceUrl] = useState('');
  const [checked, setChecked] = useState(false);
  const { i18n, t } = useTranslation();
  const { showAlert } = useContext(AlertContext);
  const eventsCtx = useContext(EventsContext);
  const memberCtx = useContext(MemberContext);
  const navigate = useNavigate();

  
  useEffect(() => {
    async function getEvents() {
      try {
        const currentLanguageExt = i18n.resolvedLanguage;
        const eventType = tab === 0 ? 'UPCOMING' : 'PAST';
        const events = await fetchEvents(eventType);
       
        events.upcoming = events.upcoming.map((event) => ({
          ...event,
          title: event.title[currentLanguageExt],
          description: event.description[currentLanguageExt],
          address: event.address[currentLanguageExt]
        }));

        events.past = events.past.map((event) => ({
          ...event,
          title: event.title[currentLanguageExt],
          description: event.description[currentLanguageExt],
          address: event.address[currentLanguageExt]
        }));

        eventsCtx.setEvents(events);

      } catch(err) {
        console.log('Cannot fetch events!');
        showAlert('error', t(`${err.msg}`, { ns: 'alerts' }));
      }
    }

    getEvents();
    setChecked(true);
  }, [i18n.resolvedLanguage]); 

  const events = eventsCtx.events;

  const openConferenceDialog = (url) => {
    setConferenceUrl(url);
    setConferenceDialogOpen(true);
  };

  const closeConferenceDialog = () => {
    setConferenceDialogOpen(false);
  };

  const isMemberJoined = (event) => {
    const id = memberCtx.profile && memberCtx.profile.id;
    return id ? event.profiles.some((item) => item.id == id) : false;
  };

  return (
    <ViewContainer direction={'column'} position={{ justifyContent: 'center', alignItems:'center' }}> 
      <Grid container direction="row" justifyContent="center" mb={5}>
        <Grid item sx={{display: 'flex', justifyContent: 'center'}} xs={12}>
          <ViewTabs tabs={tabs} tabState={tab} tabStateHandler={selectTab}/>
        </Grid>  
        <Grid item xs={10} mt={5}>
          <EventsTabPanel value={tab} index={0}>
            <Grid container rowSpacing={10}>
              {events.upcoming.map((e) => {
                const isJoined = isMemberJoined(e);
                return(
                  <Grow key={e.id} in={checked} style={{ transitionDuration: '600ms' }}>
                    <Grid item xs={12}>
                      <EventCard 
                        coverUrl={e.coverUrl} 
                        coverType={e.coverType} 
                        title={e.title} 
                        startDate={e.startDate}
                        endDate={e.endDate}
                        address={e.address}
                        conferenceUrl={e.online_conference_url}
                        isJoined={isJoined}
                        description={e.description}
                        isPassed={tab === 1}
                        onButtonClick={ isJoined && e.online_conference_url 
                          ? () => openConferenceDialog(e.online_conference_url)
                          : () => navigate(`${e.id}/join`)
                        }
                      />
                    </Grid>
                  </Grow>
                )
              })}
            </Grid>
          </EventsTabPanel>
        </Grid>
        <Grid item xs={10}>
          <EventsTabPanel value={tab} index={1}>
            <Grid container rowSpacing={10}>
              {events.past.map((e) => (
                <Grow key={e.id} in={checked} style={{ transitionDuration: '600ms' }}>
                  <Grid item  xs={12}>
                    <EventCard 
                      coverUrl={e.coverUrl} 
                      coverType={e.coverType} 
                      title={e.title} 
                      date={e.date}
                      address={e.address}
                      description={e.description}
                      isPassed={tab === 1}
                    />
                </Grid>
                </Grow>
              ))}
            </Grid>
          </EventsTabPanel>
        </Grid>
      </Grid>
      <ConferenceDialog
        open={conferenceDialogOpen}
        onClose={closeConferenceDialog}
        conferenceUrl={conferenceUrl}
      />
    </ViewContainer>
  )
}

function EventsTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      sx={{ width: '100%' }}
      {...other}
    >
      {value === index && (children)}
    </Box>
  );
}
