import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useField } from 'formik';

export default function DegreeSelect({ name, label, options }) {
  const [field, meta, helpers] = useField(name);

  const handleChange = (event) => {
    helpers.setValue(event.target.value);
  };

  return (
    <FormControl variant="standard" sx={{ width: '100%' }} error={meta.touched && Boolean(meta.error)}>
      <InputLabel id={`${name}-label`}>{label}</InputLabel>
      <Select
        labelId={`${name}-label`}
        id={name}
        {...field}
        onChange={handleChange}
        label={label}
        MenuProps={{ disableScrollLock: true }}
      >
        {options.map((opt,i) => (
          <MenuItem key={i} value={opt.value}>{opt.label}</MenuItem>
        ))}
      </Select>
      {meta.touched && meta.error ? (
        <span style={{ color: 'rgba(211, 47, 47, 1)', fontSize: '0.75rem' }}>{meta.error}</span>
      ) : null}
    </FormControl>
  );
}
