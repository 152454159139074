import { useContext, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import CircularProgress from '@mui/material/CircularProgress';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { fetchEventPricePeriods } from '../../utils/http'; 
import { MemberContext } from '../../store/MemberContext';
import { EventsContext } from '../../store/EventsContext';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import VisitorRegisterStep from '../../components/VisitorRegisterStep';
import EventDetailsStep from '../../components/EventDetailsStep';
import LoadingOverlay from '../../components/LoadingOverlay';


export default function EventJoin() {
  const { eventId } = useParams();
  const navigate = useNavigate();
  const [eventContent, setEventContent] = useState(null);
  const [loadingOverlayState, setLoadingOverlayState] = useState(false);
  const [eventPrices, setEventPrices] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const { profile } = useContext(MemberContext);
  const { events } = useContext(EventsContext);

  const { t } = useTranslation();

  const theme = useTheme();
  const isXsScreen = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    async function fetchEventDetailsAndPrices() {
      try {
        const eventDetails = events.upcoming.find((_event) => _event.id === parseInt(eventId));
        const profileJoined = eventDetails && profile && eventDetails.profiles.some((_p) => _p.id === profile.id) 

        if (!eventDetails || profileJoined) {
          navigate('/events', { replace: true });
          return;
        }

        const pricePeriods = await fetchEventPricePeriods(eventId);
        setEventContent(eventDetails);
        setEventPrices(pricePeriods.data);
      } catch (err) {
        console.error(err);
        navigate('/events', { replace: true });
      }
    }

    fetchEventDetailsAndPrices();
  }, [eventId]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const steps = [
    {
      label: t('step_one_label', { ns: 'events' }),
      component: <EventDetailsStep {...eventContent } prices={eventPrices} handleNext={handleNext}/>,
    },
    {
      label: t('step_two_label', { ns: 'events' }),
      component: <VisitorRegisterStep setOverlayState={setLoadingOverlayState} profile={profile} eventId={eventContent && eventContent.id} prices={eventPrices}/>,
    },
    {
      label: t('step_three_label', { ns: 'events' }),
      component: <Box>'Moving to payment...'</Box>,
    },
  ];

  if (!eventContent){
    return (
      <Box sx={{ 
        width:{ md: 'calc(100% - 240px)' }, 
        minHeight: 'calc(100vh - 70px)',
        padding: '25px',
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        height: '100vh'
      }}>
        <CircularProgress />
      </Box>
    )
  }

  return (
    <Box sx={{
      width:{ md: 'calc(100% - 240px)' }, 
      minHeight: 'calc(100vh - 70px)',
      padding: '25px'
    }}> 
      <LoadingOverlay open={loadingOverlayState} />
      <Grid container rowSpacing={2} columnSpacing={3} justifyContent={'center'}>
        <Grid item xs={12} md={10} mt={{ xs: 10, md: 3 }}>
          <Stepper activeStep={activeStep} orientation="horizontal">
            {steps.map((step, index) => (
              <Step key={index}>
                <StepLabel>
                  {isXsScreen ? (index === activeStep ? step.label : '') : step.label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
          { activeStep !== 0 && (
            <Button
              onClick={handleBack}
              sx={{ mt: 3, ml: 0 }}
            >
              <ArrowBackIcon />
            </Button>
            )
          }
        </Grid>
        <Grid item xs={10}>
          {steps[activeStep].component}
        </Grid>
      </Grid>
    </Box>
  );
}
