import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow'
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';


dayjs.extend(utc);
dayjs.extend(timezone);

const TIMEZONE = 'Asia/Yerevan';

export default function EventPriceCard({ startDate, endDate, prices }){
  const { t } = useTranslation();

  const generatePricingList = (priceInfo, category) => {
    const formatPricePeriod = (_startDate, _endDate) => {
      return _endDate
        ? `${dayjs(_endDate).tz(TIMEZONE).format('DD-MM-YYYY')} - ${dayjs(_startDate).tz(TIMEZONE).format('DD-MM-YYYY')}`
        : `${dayjs(_startDate).tz(TIMEZONE).format('DD-MM-YYYY')}`;
    };
  
    const pricePeriods = [];
  
    if (priceInfo.length === 1) {
      pricePeriods.push({
        name: t('detail_step.current_price', { ns: 'events' }),
        dates: null,
        price: priceInfo[0].price,
        isCurrent: true
      });      
    } else {
      priceInfo.forEach((period, index) => {
        if (index === 0) {
          const isEventDayPeriod = dayjs(period.date).isSame(startDate, 'day');
          const formattedPeriod = isEventDayPeriod
            ? {
                name: t('detail_step.on_event_days', { ns: 'events' }),
                dates: formatPricePeriod(endDate || startDate, period.date),
                price: period.price
              }
            : {
                name: t('detail_step.price_period_from', { ns: 'events' }),
                dates: endDate ? formatPricePeriod(endDate, period.date) : formatPricePeriod(startDate, period.date),
                price: period.price
              };
    
          pricePeriods.push(formattedPeriod);
        } else if (index < priceInfo.length - 1) {
          const previousPeriodDate = dayjs(priceInfo[index - 1].date).tz(TIMEZONE);
          const formattedPeriod = {
            name: t('detail_step.price_period_from', { ns: 'events' }),
            dates: `${dayjs(period.date).tz(TIMEZONE).format('DD-MM-YYYY')} - ${previousPeriodDate.subtract(1, 'day').format('DD-MM-YYYY')}`,
            price: period.price
          };
  
          pricePeriods.push(formattedPeriod);
        }
    
        if (index === priceInfo.length - 1 && index !== 0) { // Last period (current price)
          const previousPeriodDate = dayjs(priceInfo[index - 1].date).tz(TIMEZONE);
          pricePeriods.push(
            {
              name: t('detail_step.price_period_till', { ns: 'events' }),
              dates: previousPeriodDate.subtract(1, 'day').format('DD-MM-YYYY'),
              price: period.price,
              isCurrent: false
            }
          );
  
          pricePeriods.push(
            {
              name: t('detail_step.current_price', { ns: 'events' }),
              dates: null,
              price: period.price,
              isCurrent: true
            }
          );
        }
      });
    }    
  
    return ( 
      <TableContainer component={Paper} elevation={3}>
        <Typography variant="h6" gutterBottom component="div" sx={{ p: 2 }}>
          {t(`detail_step.${category}`, { ns: 'events' })}
        </Typography>
        <Table aria-label={`${t(`detail_step.${category}`, { ns: 'events' })} pricing`}>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell align="right">{t(`detail_step.date_column`, { ns: 'events' })} (GMT+4)</TableCell>
              <TableCell align="right">{t(`detail_step.price_column`, { ns: 'events' })} ({t('currency_amd', { ns: 'events' })})</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {pricePeriods.map((period, index) => (
              <TableRow key={index}>
                <TableCell component="th" scope="row" sx={{ fontWeight: period.isCurrent ? 'bold' : 'normal' }}>
                  {period.name}
                </TableCell>
                <TableCell align="right">{period.dates}</TableCell>
                <TableCell align="right"sx={{ fontWeight: period.isCurrent ? 'bold' : 'normal' }}>{period.price}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    )
  };

  return(
    <Box>
      <Typography variant="h6" gutterBottom>{t('detail_step.pricing_title', { ns: 'events' })}</Typography>
      <Typography variant="body2" gutterBottom>{t('detail_step.pricing_note', { ns: 'events' })}</Typography>
      <Divider mt={2} mb={2}/>
      {prices ? generatePricingList(prices['physician'], 'physician'): null}
      <Box sx={{ my: 2 }} />
      {prices ? generatePricingList(prices['resident'], 'resident'): null}
    </Box>
  )
};