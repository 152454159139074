import Grid from "@mui/material/Grid";
import Slide from "@mui/material/Slide";
import ListingCard from "../../components/ListingCard";
import InsertLinkSharpIcon from '@mui/icons-material/InsertLinkSharp';
import { useTranslation } from "react-i18next";
import ViewContainer from "../../components/ViewContainer";
import { useEffect, useState } from "react";

export default function Links(){
  const { t } = useTranslation();
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    setChecked(true);
  },[]);

  /** TODO
   * When content will be available, move title and content
   * data to locales configured by i18next plurals 
   */
  const linksData = [
    { 
      url: 'https://google.com', 
      title:t('card_one.title', { ns: 'aboutUs' }), 
      content:t('card_one.description', { ns: 'aboutUs' }), 
      icon: InsertLinkSharpIcon 
    },
    { 
      url: 'https://youtube.com', 
      title:t('card_two.title', { ns: 'aboutUs' }), 
      content:t('card_two.description', { ns: 'aboutUs' }), 
      icon: InsertLinkSharpIcon 
    },
    { 
      url: 'https://google.com', 
      title:t('card_two.title', { ns: 'aboutUs' }), 
      content:t('card_two.description', { ns: 'aboutUs' }), 
      icon: InsertLinkSharpIcon 
    },
    { 
      url: 'https://google.com', 
      title:t('card_one.title', { ns: 'aboutUs' }), 
      content:t('card_one.description', { ns: 'aboutUs' }), 
      icon: InsertLinkSharpIcon 
    },
  ];

  return( 
    <ViewContainer>
      <Grid container sx = {{ 
        display: 'flex',  
        flexWrap: 'wrap', 
        flexDirection: 'row', 
        justifyContent: 'center',
        marginTop: { xs: 1, md: 2 },
        marginBottom: 10
        }} rowSpacing={8} columnSpacing={3}>
          { linksData.map((link,i) => (
            <Slide key={i} direction={ i % 2 === 0 ? "right" : "left"} in={checked}>
              <Grid item  xs={10} sm={11} md={5} sx={{ display: 'flex', justifyContent: 'center'}} >
                <ListingCard 
                  titleLink={link.url}
                  title={link.title} 
                  content={link.content} 
                  icon={link.icon}
                />
              </Grid>
            </Slide>
          ))}
      </Grid>
    </ViewContainer>
  )
}