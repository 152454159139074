import Box from '@mui/material/Box';

export default function ViewContainer({ children, direction, position }) {
  return (
    <Box sx={{ 
      display:'flex', 
      flexWrap: 'wrap', 
      flexDirection: direction || 'row',
      width:{ md: 'calc(100% - 240px)' }, 
      minHeight: 'calc(100% - 70px)',
      justifyContent: position ? position.justifyContent : 'center',
      alignItems: position ? position.alignItems :'stretch'
    }}> {children} </Box>
  )
}