import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import CustomModal from './CustomModal';

export default function TermsModal({ modalState, handleClose }) {
  const { t } = useTranslation();

  return (
    <CustomModal
      style={{
        width: { xs: "95%", sm: '80%', md: '55%', lg: '40%'},
        padding: '25px 25px 25px 35px'
      }}
      handleClose={handleClose}
      modalState={modalState}
    >
      <Grid container justifyContent={"space-around"} rowSpacing={2} columnSpacing={2}>
        <Grid item xs={12} container justifyContent='center' alignItems='center' mt={{ xs: 7, sm: 5, md:2 }}>
          <Typography variant="h5" color={'primary'} gutterBottom align="center">
            { t('title', { ns: 'terms' }) }
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item container xs={12} sx={{ padding:"0px 0px 0px 0px"}}>
          <Typography variant="h6" color={"primary"} gutterBottom>
            { t('subtitle_1', { ns: 'terms' }) }
          </Typography>
          <Typography paragraph>
            { t('subtitle_2', { ns: 'terms' }) }
          </Typography>

          { Array.from({ length: 15 }, (_, i) => (
            i % 2 === 0 
            ? (<Typography key={i} paragraph>{ t(`paragraph_${i+1}`, { ns: 'terms' }) }</Typography>) 
            : (<Typography key={i} variant="h6" color={'primary'} gutterBottom>{ t(`paragraph_${i+1}`, { ns: 'terms' }) }</Typography>)
          )) }

          <Typography paragraph>
            { t(`paragraph_end`, { ns: 'terms' }) }
          </Typography> 
        </Grid>
      </Grid>
    </CustomModal>
  );
}