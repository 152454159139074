import { useState, useEffect } from 'react';
import { Gb, Am, Ru } from 'react-flags-select';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    error: {
      main: 'rgba(0,0,0,0)'
    }
  },
});

export default function HeaderLanguages(){
  const { i18n } = useTranslation();
  const [lang, setLang] = useState('');

  const handleChange = (event) => {
    i18n.changeLanguage(event.target.value)
  };

  const lngs = [
    { component: <Gb fontSize="1.5rem"/>, code: 'en' },
    { component: <Ru fontSize="1.5rem"/>, code: 'ru' },
    { component: <Am fontSize="1.5rem"/>, code: 'am' }
  ];

  useEffect(() => {
    setLang(i18n.resolvedLanguage);
  },[i18n.resolvedLanguage]);
 
  return (
    <ThemeProvider theme={theme}>
      <Box width={70}>
        <FormControl fullWidth error>
          <Select
            value={lang}
            onChange={handleChange}
            sx={{ height: '50px', paddingTop: '7px' }}
          >
            {lngs.map(lngItem => ( 
              <MenuItem key={lngItem.code} value={lngItem.code} sx={{paddingLeft: '23px'}}>{lngItem.component}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </ThemeProvider>
  )
}