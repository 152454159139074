import React from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import CustomModal from './CustomModal';
import { ThemeProvider, createTheme } from '@mui/material/styles';

const theme = createTheme({
  components: {
    MuiTypography: {
      styleOverrides: {
        paragraph: ({ theme }) => ({
          fontSize: '0.875rem', 
          [theme.breakpoints.up('sm')]: {
            fontSize: '1rem', 
          },
          [theme.breakpoints.up('md')]: {
            fontSize: '1rem',
          }
        }),
      },
    },
  },
});


export default function PrivacyModal({ modalState, handleClose }) {
  const { t } = useTranslation();

  const renderTextWithLineBreaks = (text) => {
    const textParts = text.split('\n');
    return textParts.map((part, index) => (
      <React.Fragment key={index}>
        {part}
        {index < textParts.length - 1 && <br />}
      </React.Fragment>
    ));
  }

  return (
    <ThemeProvider theme={theme}>
      <CustomModal
        style={{
          width: { xs: "95%", sm: '80%', md: '55%', lg: '40%'},
          padding: '25px 25px 25px 35px'
        }}
        handleClose={handleClose}
        modalState={modalState}
      >
        <Grid container justifyContent={"space-around"} rowSpacing={2} columnSpacing={2}>
          <Grid item xs={12} container justifyContent='center' alignItems='center' mt={{ xs: 7, sm: 5, md:2 }}>
            <Typography variant="h5" color={'primary'} gutterBottom align="center">
              { t('title', { ns: 'privacyPolicy' }) }
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item container xs={12} sx={{ padding:"0px 0px 0px 0px"}}>
            <Typography variant="subtitle1" color={"primary"} gutterBottom>
              { t('subtitle_1', { ns: 'privacyPolicy' }) }
            </Typography>
            <Typography variant="subtitle1" color={"primary"} mb={3} gutterBottom>
              { t('subtitle_2', { ns: 'privacyPolicy' }) }
            </Typography>

            { Array.from({ length: 13 }, (_, i) => (
              <Box key={i}> 
                <Typography variant="h6" color={'primary'} gutterBottom>{ t(`paragraph_${i+1}_title`, { ns: 'privacyPolicy' }) }</Typography>
                <Typography paragraph>{ renderTextWithLineBreaks(t(`paragraph_${i+1}`, { ns: 'privacyPolicy' })) }</Typography> 
              </Box>
            )) }
          </Grid>
        </Grid>
      </CustomModal>
    </ThemeProvider>
  );
}