import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import { DrawerContent } from './DrawerContent';
import DrawerLanguages from './DrawerLanguages';
import SocialMediaLinks from './SocialMediaLinks';

const drawerWidth = 240;

export default function SideDrawer({ mobileDrawerState, handleDrawerToggle, navs, openModal }){ 
  return (
    <Box sx={{ display: 'flex' }} >
      <CssBaseline />
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          variant="temporary"
          open={mobileDrawerState}
          anchor='right'
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: 'block', md: 'none' },
            '& .MuiDrawer-paper': { 
              boxSizing: 'border-box', 
              width: drawerWidth, 
              backgroundImage: 'linear-gradient(to top right, #3498db, #16a085);' 
            },
          }}
        >
          { DrawerContent({ navs, handleDrawerToggle, openModal }) }
        </Drawer>
        <Drawer
          variant="permanent"
          anchor='right'
          elevation={4}
          // sx={{
          //   display: { xs: 'none', md: 'block' },
          //   '& .MuiDrawer-paper': { 
          //     boxSizing: 'border-box', 
          //     width: drawerWidth, 
          //     backgroundImage: 'linear-gradient(to top right, #3498db, #16a085);' 
          //   },
          // }}
          sx={{
            display: { xs: 'none', md: 'block' },
            '& .MuiDrawer-paper': { 
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              height: '100%',
              boxSizing: 'border-box', 
              width: drawerWidth,
              backgroundImage: 'linear-gradient(to top right, #3498db, #16a085);'
            }
          }}
        >
          { DrawerContent({ navs, openModal }) }
          <Box>
              <DrawerLanguages/>
              <SocialMediaLinks/>
          </Box>

        </Drawer>
      </Box>
    </Box>
  );
}