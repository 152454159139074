import React from 'react';
import { useField } from 'formik';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

const CheckBoxInput = ({ name, label, required, ...otherProps }) => {
  const [field, meta] = useField(name);
  const configCheckbox = {
    ...field,
    ...otherProps,
  };

  const labelStyle = {
    color: required && meta.touched && meta.error ? 'rgba(211, 47, 47, 1)' : 'inherit',
  };

  return (
    <FormControlLabel
      control={<Checkbox {...configCheckbox} />}
      label={label}
      name={name}
      style={labelStyle}
    />
  );
};

export default CheckBoxInput;
