import { useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import HeaderLogo from './HeaderLogo';
import HeaderOverLay from './HeaderOverLay';


export default function HeaderContainer({ children }){
  const location = useLocation();
  const isNonHeaderPage = location.pathname.split('/').pop() === 'join';
 
  return isNonHeaderPage 
    ? (<Box>{children}</Box>)
    : (
      <Box
        sx={{
          position: 'relative',
          minHeight: { xs: '35vh', lg: '45vh' },
          backgroundImage: 'url(/images/header3_.jpg)',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: { xs: '0% 35%', sm: '25% 45%', md: '0% 63%', lg: '0% 55%', xl: '0% 62%' },
          borderBottom: '3px solid #3498db',
          overflow: 'hidden',
        }}
      >
        <HeaderOverLay/>
        <HeaderLogo/>
        <Box sx={{ position: 'relative', zIndex: 1 }}>
          {children}
        </Box>
      </Box>
    )
}
