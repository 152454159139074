import React, { createContext, useReducer } from 'react';

export const EventsContext = createContext({
  events: {
    upcoming: [],
    past: []
  },
  setEvents: (events) => {}
});

function eventsReducer(state, action) {
  switch (action.type) {
    case 'SET':
      return { ...state, events: action.payload };
    case 'UPDATE_MEMBERS':
        return { ...state, events: action.payload };
    default:
      return state;
  }
}

function EventsContextProvider({ children }) {
  const [eventState, dispatch] = useReducer(eventsReducer, { events: { upcoming: [], past: [] } });
  
  function setEvents(_events) {
    dispatch({ type: 'SET', payload: _events });
  }

  function updateMembers(eventId,members) {
    eventState.events.upcoming.forEach((item, i) => {
      if(item.id === eventId){
        eventState.events.upcoming[i].profiles = members;
      }
    });

    dispatch({ type: 'UPDATE_MEMBERS', payload: eventState.events });
  }
  
  return (
    <EventsContext.Provider value={{ events: eventState.events, setEvents, updateMembers }}>
      {children}
    </EventsContext.Provider>
  );
}

export default EventsContextProvider;
