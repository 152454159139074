import React, { createContext, useState } from 'react';

export const MemberContext = createContext({
  id: null,
  profile_id: null,
  profile: null,
  setVisitorProfile:  (profile) => {}
});

function MemberContextProvider({ children }) {
  const [memberState, setMemberState] = useState({ id: null, profile_id: null, profile: null });

  function setVisitorProfile(visitor){
    if(!visitor.data) localStorage.removeItem('temp_id');
    setMemberState((prevState) => ({ ...prevState,  profile: visitor.data }));
  }

  const value = {
    ...memberState,
    setVisitorProfile
  };

  return (
    <MemberContext.Provider value={value}>
      {children}
    </MemberContext.Provider>
  );
}

export default MemberContextProvider;
