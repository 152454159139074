import Grid from "@mui/material/Grid";
import Grow from "@mui/material/Grow";
import ViewContainer from "../../components/ViewContainer";
import ListingCard from "../../components/ListingCard";
import FavoriteSharpIcon from '@mui/icons-material/FavoriteSharp';
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";

const cardIcons = {
  main: FavoriteSharpIcon,
  secondary: FavoriteSharpIcon
};

export default function Rhinoplasty(){
  const { t } = useTranslation();
  const [checked, setChecked] = useState(false);
  
  useEffect(() => {
    setChecked(true);
  },[]);

  return( 
    <ViewContainer>
      <Grid container sx = {{ 
        display: 'flex',  
        flexWrap: 'wrap', 
        flexDirection: 'row', 
        justifyContent: 'center',
        marginTop: { xs: 1, md: 4 },
        marginBottom: 10
        }} rowSpacing={8} columnSpacing={3}>
          { Object.entries(cardIcons).map(([card, icon], i) => (
              <Grow key={i} in={checked} style={{ transitionDuration: '600ms' }}>
                <Grid item xs={10} sm={11} md={5} sx={{ display: 'flex', justifyContent: 'center'}} >
                  <ListingCard 
                    title={t(`${card}.title`, { ns: 'rhinoplasty' })} 
                    content={t(`${card}.description`, { ns: 'rhinoplasty' })} 
                    nav={{ to: `/rhinoplasty/${card}`, title:t(`${card}.button_label`, { ns: 'rhinoplasty' }) }}
                    icon={icon}
                  />
                </Grid>
              </Grow>
          ))}
      </Grid>
    </ViewContainer>
  )
}