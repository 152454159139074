import { FormControl, FormControlLabel, Radio, FormLabel, RadioGroup, Grid } from '@mui/material';
import { useField, useFormikContext } from 'formik';

export default function RadioGroupFormik({ name, options, label, onChange }) {
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(name);

  const handleChange = (event) => {
    setFieldValue(name, event.target.value);
    if (onChange) {
      onChange(event.target.value); 
    }
  };

  return (
    <FormControl component="fieldset" error={meta.touched && !!meta.error} fullWidth>
      <FormLabel component="legend">{label}</FormLabel>
      <RadioGroup name={name} value={field.value} onChange={handleChange}>
        <Grid container width={"100%"}>
          {options.map((option) => (
            <Grid item xs={12} md={4} key={option.value}>
              <FormControlLabel
                value={option.value}
                control={<Radio />}
                label={option.label}
              />
            </Grid>
          ))}
        </Grid>
      </RadioGroup>
    </FormControl>
  );
}
