import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { countries } from '../utils/countriesData';
import { useFormikContext, useField } from 'formik';

export default function CountrySelect({ name, ...props }) {
  const { setFieldValue, values } = useFormikContext();
  const [field, meta] = useField(name);

  const handleSelect = (event, value) => {
    setFieldValue(name, value ? value.label : '');
  };

  const currentValue = countries.find(country => country.label === values[name]) || null;

  return (
    <Autocomplete
      sx={{ width: "100%" }}
      options={countries}
      autoHighlight
      getOptionLabel={(option) => option.label}
      onChange={handleSelect}
      value={currentValue}
      renderOption={(props, option) => (
        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
          <img
            loading="lazy"
            width="20"
            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
            alt=""
          />
          ({option.code}) {option.label}
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          variant='standard'
          label={params.label}
          inputProps={{
            ...params.inputProps,
          }}
          {...props} 
          error={meta.touched && Boolean(meta.error)}
          helperText={meta.touched && meta.error ? meta.error : ''}
        />
      )}
    />
  );
}
