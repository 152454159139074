import React from 'react';
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import ViewNavigationContainer from "../../components/ViewNavigationContainer";
import { useTranslation } from "react-i18next";
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  components: {
    MuiTypography: {
      styleOverrides: {
        body1: ({ theme }) => ({
          fontSize: '0.875rem', 
          [theme.breakpoints.up('sm')]: {
            fontSize: '1rem', 
          },
          [theme.breakpoints.up('md')]: {
            fontSize: '0.9rem',
          },
          [theme.breakpoints.up('lg')]: {
            fontSize: '1.1rem',
          },
          [theme.breakpoints.up('xl')]: {
            fontSize: '1.2rem',
          }
        }),
      },
    },
  }
});

export default function MainMeeting() {
  const { t } = useTranslation();

  return (
    <ThemeProvider theme={theme}>
    <ViewNavigationContainer path='/home/ihmrs'>
      <Grid container justifyContent="center" sx={{ marginTop: { xs: 2, md: 3 }, marginBottom: 10 }}>
        <Grid item xs={10} md={11}>
          <Card sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, alignItems: 'center' }}>
            {/* Image Gallery Grid */}
            <Grid item container xs={12} md={6} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Grid item xs={12} md={10}>
                <img src='/images/gallery/1.jpg' alt="Gallery Image 1" style={{ border: '2px solid #f5f5f5', width: '100%', height: 'auto', display: 'block' }} />
              </Grid>
              <Grid item xs={6} md={5}>
                <img src='/images/gallery/2.jpg' alt="Gallery Image 2" style={{ border: '2px solid #f5f5f5', width: '100%', height: 'auto', display: 'block' }} />
              </Grid>
              <Grid item xs={6} md={5}>
                <img src='/images/gallery/3.jpg' alt="Gallery Image 3" style={{ border: '2px solid #f5f5f5', width: '100%', height: 'auto', display: 'block' }} />
              </Grid>
              <Grid item xs={12} md={10}>
                <img src='/images/gallery/4.jpg' alt="Gallery Image 4" style={{ border: '2px solid #f5f5f5', width: '100%', height: 'auto', display: 'block' }} />
              </Grid>
            </Grid>

            {/* Text Content */}
            <CardContent sx={{ flex: 1 }}>
              <Typography textAlign='left' variant="body1" style={{ whiteSpace: 'pre-line' }}>
                {t('card_one.description', { ns: 'home' })}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </ViewNavigationContainer>
    </ThemeProvider>
  );
}
