import Grid from "@mui/material/Grid";
import Slide from "@mui/material/Slide";
import ViewContainer from "../../components/ViewContainer";
import ListingCard from "../../components/ListingCard";
import AnnouncementSharpIcon from '@mui/icons-material/AnnouncementSharp';
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const cardIcons = {
  card_one: AnnouncementSharpIcon,
  card_two: AnnouncementSharpIcon,
  card_three: AnnouncementSharpIcon,
  card_four: AnnouncementSharpIcon,
  card_five: AnnouncementSharpIcon,
  card_six: AnnouncementSharpIcon,
};

export default function AboutUs(){
  const { t } = useTranslation();
  
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    setChecked(true);
  },[]);

  return( 
    <ViewContainer>
      <Grid container sx = {{ 
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        justifyContent: 'center',
        marginTop: { xs: 1, md: 2 },
        marginBottom: 10
        }} rowSpacing={5} columnSpacing={3}>
          { Object.entries(cardIcons).map(([card, icon], i) => (
              <Slide key={i} direction={ i % 2 === 0 ? "right" : "left"} in={checked}>
                <Grid item xs={10} sm={11} md={5} sx={{ display: 'flex', justifyContent: 'center'}} >
                  <ListingCard 
                    title={t(`${card}.title`, { ns: 'aboutUs' })} 
                    content={t(`${card}.description`, { ns: 'aboutUs' })} 
                    nav={{ to: '/about-us', title:t(`${card}.button_label`, { ns: 'aboutUs' }) }}
                    icon={icon}
                  />
                </Grid>
              </Slide>
            )
          )}
      </Grid>
    </ViewContainer>
  )
}