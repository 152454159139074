import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import PrivacyModal from './PrivacyModal';

export default function Footer() {
  const { t } = useTranslation();
  const [modalState, setModalState] = useState(false);

  return (
    <Box sx={{ width:{ md: 'calc(100% - 240px)' } }}>
      <Paper sx={{ bottom: 0, left: 0, right: 0, height: { xs: 165, sm: 93, md: 70 }, borderTop: "0.3px solid #3498db", textAlign: 'center'}} elevation={3} square>
        <Typography variant='body1' component="p" mt={2.5}>
          {/* Replace this part with Link component */}
          <Link component="button" variant="body1" sx={{ mr: 1 }} onClick={() => setModalState(true)}>
            {t('footer.privacy_policy', { ns: 'layout' })}
          </Link> 
          | {t('footer.support', { ns: 'layout' })} rhinoplastyarm@gmail.com , (+374)77051677 | © {new Date().getFullYear()} ars.am. {t('footer.reserved_rights', { ns: 'layout' })}.
        </Typography>
      </Paper>
      <PrivacyModal modalState={modalState} handleClose={() => setModalState(false)}/>
    </Box>
  );
}
