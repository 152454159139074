import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const LoadingOverlay = ({ open }) => {
    return (
        <Backdrop
            sx={{ color: '#1d9e9a', zIndex: (theme) => theme.zIndex.drawer + 1000 }}
            open={open}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
    );
};

export default LoadingOverlay;