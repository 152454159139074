import { useContext, useEffect } from "react";
import Grid from "@mui/material/Grid";
import ListingCard from "../../components/ListingCard";
import ImageCarousel from "../../components/ImageCarousel";
import CommentSharpIcon from '@mui/icons-material/CommentSharp';
import GroupsSharpIcon from '@mui/icons-material/GroupsSharp';
import { useTranslation } from "react-i18next";
import ViewContainer from "../../components/ViewContainer";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from "@mui/material/styles";
import { AlertContext } from "../../store/AlertContext";
import { ContentContext } from "../../store/ContentContext";

export default function Home(){
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const mediumDimension = useMediaQuery(theme.breakpoints.down('md'));
  const largeDimension= useMediaQuery(theme.breakpoints.down('lg'));
  const xlargeDimension= useMediaQuery(theme.breakpoints.down('xl'));
  const smallDimension = useMediaQuery(theme.breakpoints.down('sm'));
  const { showAlert } = useContext(AlertContext);
  const { content, clearAlert } = useContext(ContentContext);

  useEffect(() => {
    if(content && content.alert.message){
      showAlert(content.alert.type, t(`${content.alert.message}`, { ns: 'alerts' }));
      return () => clearAlert();
    }
  },[]);

  let imageUrls = [];
  let imageHeight = 500;

  switch (true) {
    case smallDimension:
      imageUrls = [
        `/images/carousel/LogoRhyno.jpg`,
        `/images/carousel/banner_${i18n.resolvedLanguage}.png`,
      ];
      imageHeight = 290;
      break;
    case mediumDimension:
      imageUrls = [
        `/images/carousel/LogoRhyno.jpg`,
        `/images/carousel/banner_${i18n.resolvedLanguage}.png`,
      ];
      imageHeight = 500;
      break;
    case largeDimension:
      imageUrls = [
        `/images/carousel/LogoRhyno.jpg`,
        `/images/carousel/banner_${i18n.resolvedLanguage}.png`,
      ];
      imageHeight = 400;
      break;
    case xlargeDimension:
      imageUrls = [
        `/images/carousel/LogoRhyno.jpg`,
        `/images/carousel/banner_${i18n.resolvedLanguage}.png`,
      ];
      imageHeight = 500;
      break;
    default:
      imageUrls = [
        `/images/carousel/LogoRhyno.jpg`,
        `/images/carousel/banner_${i18n.resolvedLanguage}.png`,
      ];
      imageHeight = 600;
      break;
  }

 
  return( 
    <ViewContainer>
      <Grid container rowSpacing={2} my={3}>
        <Grid item container order={{ xs: 2, md: 1 }} xs={12} md={6} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <Grid item xs={10} my={3} sx={{ display: 'flex', justifyContent: 'center' }}>
            <ListingCard 
              title={t('card_one.title', { ns: 'home' })} 
              content={t('card_one.description', { ns: 'home' })} 
              nav={{ to: '/ihmrs', title:t('card_one.button_label', { ns: 'home' }) }}
              icon={GroupsSharpIcon}
            />
          </Grid>
          <Grid item xs={10} my={3} sx={{ display: 'flex', justifyContent: 'center' }}>
            <ListingCard 
              title={t('card_two.title', { ns: 'home' })} 
              content={t('card_two.description', { ns: 'home' })} 
              nav={{ to: '/message', title:t('card_two.button_label', { ns: 'home' }) }}
              icon={CommentSharpIcon}  
            />
          </Grid>
        </Grid>
        <Grid item container order={{ xs: 1, md: 2 }} xs={12} md={6} mt={3} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <Grid item xs={10} sm={8} md={10} lg={10} xl={10} my={2}>
            <ImageCarousel 
              images={imageUrls}
              height={imageHeight}
            />
          </Grid>
        </Grid>
      </Grid>
    </ViewContainer>
  )
}