import { useTranslation } from 'react-i18next';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CustomButton from './CustomButton';
import EventPriceCard from './EventPriceCard';
import { generateEventsData } from '../utils/dateManager';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';


dayjs.extend(utc);
dayjs.extend(timezone);

const theme = createTheme({
  palette: {
    primary: {
      main: '#0055a4',
    },
    secondary: {
      main: '#e53935',
    },
    background: {
      default: '#fff',
    },
    text: {
      primary: '#333',
      secondary: '#555',
    },
  },
  typography: {
    h5: {
      fontWeight: 'bold',
      color: '#333'
    },
    body1: {
      lineHeight: 1.5
    },
  },
  components: {
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: '#f0f0f0',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        body1: ({ theme }) => ({
          fontSize: '0.875rem', 
          [theme.breakpoints.up('sm')]: {
            fontSize: '1rem', 
          },
          [theme.breakpoints.up('md')]: {
            fontSize: '1rem',
          }
        }),
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          margin: '8px',
        },
      },
    },
  },
});

const TIMEZONE = 'Asia/Yerevan';

export default function EventDetailsStep(props) {
  const {
    title,
    description,
    address,
    startDate,
    endDate,
    document_url,
    handleNext,
    prices
  } = props;

  const { day, month, year, time } = generateEventsData(startDate, TIMEZONE);

  const { t } = useTranslation();
 
  let parsedEndDate;
 
  if(endDate){
    parsedEndDate = generateEventsData(endDate, TIMEZONE);
  }

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="lg">
        <Box sx={{ my: 4 }}>
          <Typography variant="h5" gutterBottom>
            {title}
          </Typography>
          <Typography variant="body1" gutterBottom>
            {description}
          </Typography>
          <Grid container>
            <Grid item xs={12} mt={2}>
              <Typography variant="body2" color="textSecondary">
                {t('detail_step.location', { ns: 'events' })}: {address}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2" color="textSecondary">
                {t('detail_step.start_date', { ns: 'events' })}: {day}/{t(`months.${month}`, { ns: 'events' })}/{year} {time} (GMT+4)
              </Typography>
            </Grid>
            {parsedEndDate && (
              <Grid item xs={12}>
                <Typography variant="body2" color="textSecondary">
                  {t('detail_step.end_date', { ns: 'events' })}: {parsedEndDate.day}/{t(`months.${parsedEndDate.month}`, { ns: 'events' })}/{parsedEndDate.year} {parsedEndDate.time} (GMT+4)
                </Typography>
              </Grid>
            )}
            {document_url && (
              <Grid item xs={12}>
                <Link href={document_url} target="_blank" rel="noopener noreferrer" color="inherit">
                  <Typography variant="subtitle1" color="primary">
                    {t('detail_step.pdf_link', { ns: 'events' })}
                  </Typography>
                </Link>
              </Grid>
            )}
            <Grid item xs={12}>
              <Link href="https://content-ars-am.s3.eu-central-1.amazonaws.com/Document/President.pdf" target="_blank" rel="noopener noreferrer" color="inherit">
                <Typography variant="subtitle1" color="primary">
                  {t('detail_step.president_pdf', { ns: 'events' })}
                </Typography>
              </Link>
            </Grid>
          </Grid>
        </Box>
        <EventPriceCard startDate={startDate} endDate={endDate} prices={prices}/>
        <Box sx={{ my: 4 }}/>
        <Box>
          <Grid container justifyContent='flex-start'>
            <Grid item xs={12} sm={5} lg={3} xl={2}>
              <CustomButton label={t('detail_step.button_label', { ns: 'events' })} onClick={handleNext}/>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </ThemeProvider>
  );
}