import Carousel from 'react-material-ui-carousel'
import Box from '@mui/material/Box'

export default function ImageCarousel({ images, height=null }) {
  return (
    <Box>
      <Carousel 
        autoPlay={true} 
        stopAutoPlayOnHover={true} 
        animation='fade'
        interval={7000}
        height={height}
        navButtonsWrapperProps={{
          style: {
            bottom: '16px',
            top: 'unset',        
          }
        }} 
        indicators={false}
        duration={500}
        sx={{ zIndex: 0, boxShadow: '5px 5px 5px 0 rgba(0,0,0,0.2)' }}
      >
        {images.map((url, i) => (
          <img
            key={i}
            src={url}
            alt={`Slide ${i + 1}`}
            style={{ maxWidth: '100%', height: height || '500px', zIndex:0 }}
          />
        ))}
      </Carousel>
    </Box>
  )
}
