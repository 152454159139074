import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

export default function BreadCrumbs({ path }) {
  const location = useLocation(); 
  const navigate = useNavigate();
  const { t } = useTranslation();

  const paths = path ? path.split('/').slice(1) : location.pathname.split('/').slice(1);

  return (
    <Breadcrumbs separator=">">
      { paths.map((item,i) => {
          if(paths.length - 1 === i) {
            return (<Typography key={i} variant='body1' component='div'> {t(`breadcrumbs.${item}`,{ ns: 'layout' })} </Typography>);
          } 
          return (<Link underline="hover" href="#" key={i} onClick={() => navigate((i+1)-paths.length)}> {t(`breadcrumbs.${item}`,{ ns: 'layout' })} </Link>);
      })}
    </Breadcrumbs>  
  )
}