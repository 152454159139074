import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography'; 

export default function DrawerLogo(){
  return(
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingY: 1 }}>
      <img
        src="images/ars_logo_v3.png" 
        alt="ARS Logo"
        style={{ marginRight: '5px', width: '50px', height: '50px' }}
      />
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center' }}>
        <Typography variant="body2" component="p" className="drawer-label">
          Armenian
        </Typography>
        <Typography variant="body2" component="p" className="drawer-label">
          Rhinoplasty
        </Typography>
        <Typography variant="body2" component="p" className="drawer-label">
          Society
        </Typography>
      </Box>
    </Box>
  )
}