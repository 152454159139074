import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useField, useFormikContext } from 'formik';
import dayjs from 'dayjs';

export default function DatesPicker({ name, label, variant = 'standard', ...otherProps }) {
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(name);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        sx={{ width: '100%', border: '0px' }}
        {...field}
        {...otherProps}
        variant
        label={label}
        format='DD/MM/YYYY'
        value={field.value ? dayjs(field.value) : null}
        onChange={(value) => {
          setFieldValue(name, value ? value.format('YYYY-MM-DD') : '');
        }}
        slotProps={{
          textField: {
            variant: variant,
            name: name,
            helperText: meta.touched ? meta.error : "",
            error: meta.touched && Boolean(meta.error)
          },
        }}
      />
    </LocalizationProvider>
  );
}

