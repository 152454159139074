import { NavLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import DrawerLogo from './DrawerLogo';

export const DrawerContent = ({ navs, handleDrawerToggle }) => {
  const { t } = useTranslation();
  const location = useLocation();

  const onClick = handleDrawerToggle ? () => handleDrawerToggle() : null;

  return (  
    <div>
      <DrawerLogo/>
      <Divider />
      <List sx={{ marginTop:"25px"  }}>
        {navs.map((nav, index) => (
          <ListItem key={index} disablePadding>
            <ListItemButton disableRipple disableTouchRipple selected={nav.to===location.pathname} component="div" style={{ width: '100%', paddingTop: 0, paddingBottom: 0 }}>
            <NavLink to={nav.to} style={{ display: 'block', width: '100%' }} className={({ isActive })=> isActive ? "active-nav-link" : "nav-links"} replace onClick={onClick} >
              {t(nav.name, { ns: 'layout' })}
            </NavLink>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      
    </div>
  );
};