import Box from '@mui/material/Box';

export default function HeaderOverLay(){
  return (
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0)',
      }}
    />
  )
}