import { useContext } from 'react';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { AlertContext } from '../store/AlertContext';

const GlobalAlert = () => {
  const { alert, hideAlert } = useContext(AlertContext);

  if (!alert.open) return null;

  return (
    <Snackbar open={alert.open} autoHideDuration={10000} transitionDuration={200} anchorOrigin={{ 'vertical': 'top', 'horizontal': 'right' }} onClose={hideAlert}>
      <Alert severity={alert.type} variant='filled' onClose={hideAlert}>
        {alert.message}
      </Alert>
    </Snackbar>
  );
};

export default GlobalAlert;