import { Link, useNavigate } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { styled } from '@mui/material/styles';
import AttachFileSharpIcon from '@mui/icons-material/AttachFileSharp';
import LinesEllipsis from 'react-lines-ellipsis';
import CustomButton from './CustomButton';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  width: "100%",
  marginTop: "30px",
  minHeight: '200px'
}));

export default function ListingCard({ title, content, nav, icon, titleLink }) {
  const navigate = useNavigate();

  const FloatingIcon = icon || AttachFileSharpIcon;

  return (
    <Box sx={{ maxWidth: '550px', minWidth: '260px'}}>
      <Item square> 
        <Box sx={{ 
          background: 'linear-gradient(to top right, #3498db, #16a085)', 
          boxShadow: '0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(50, 130, 219, 1)',
          width:"50px", 
          height:"50px", 
          float: 'left',
          marginTop: '-25px',
          borderRadius: '1px' 
        }}>
          <FloatingIcon sx={{ color: '#f5f5f5' , marginTop: '10px', fontSize: '2rem'}}/>
        </Box>
         
        <Typography variant="h6" component='div' mr="50px">
          { titleLink 
            ? <Link to={titleLink} target="blank" className="links">{title}</Link> 
            : title
          }
        </Typography>
        
        <Box sx={{ padding: "13px 30px" }}>
          <Divider/>
          <Typography 
            variant='body2' 
            component='div' 
            sx={{
              textAlign:'left', 
              mt:"13px"
            }}
          >
            <LinesEllipsis 
              text={content}
              basedOn="words"
              maxLine='5'
              trimRight
              ellipsis="..."
            />
              
          </Typography>
        </Box>
        <Box sx={{ width: '100%', padding: "5px 10px" }}>
          {
            nav ? (
              <CustomButton sx={{ alignItems: { xs: 'center', sm: "flex-end" }, justifyContent: 'center' }} label={nav.title} onClick={() => navigate(nav.to)}></CustomButton>
            ) : null
          }
        </Box>  
      </Item>
    </Box>
  )
}