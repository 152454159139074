import axiosInstance from './axiosInstance';

axiosInstance.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response) {
      if (error.response.status === 429) {
        error.msg = 'REQUEST_THROTTLE';
      } else {
        error.msg = error.response.data.msg || error.message;
      }
    } else if (error.request) {
      console.error("No response received:", error.request);
      error.msg = 'No response received';
    } else {
      console.error("Error setting up request:", error.message);
      error.msg = `Error setting up request: ${error.message}`;
    }
    return Promise.reject(error);
  }
);

// const BACKEND_URL = 'http://localhost:4000';
const BACKEND_URL = 'https://api.ars.am';

export async function fetchEvents(type, page, limit) {
  try{
    const queries = new URLSearchParams({ type, page, limit }).toString();
    const response = await axiosInstance.get(`${BACKEND_URL}/events?${queries}`);
    return response.data.events;
  }catch(err){
    console.log(err);
    throw err;
  }
}

export async function generateVisitorProfile(eventId, data){
  try{
    const respose = await axiosInstance.post(`${BACKEND_URL}/profile/visitor/${eventId}`, data);
    return respose.data;
  }catch(err){
    console.log(err);
    throw err;
  }
}

export async function fetchVisitorProfile(id){
  try{
    const response = await axiosInstance.get(`${BACKEND_URL}/profile/visitor/${id}`);
    return response.data;
  }catch(err){
    console.log(err);
    throw err;
  }
}


export async function updateEvent(id,data){
  try{
    const response = await axiosInstance.patch(`${BACKEND_URL}/events/${id}`, { ...data });
  
    return response.data;
  }catch(err){
    console.log(err);
    throw err;
  }
}

export async function fetchEventPricePeriods(eventId){
  try{
    const response = await axiosInstance.get(`${BACKEND_URL}/events/${eventId}/getPrices`);
    return response.data;
  }catch(err){
    console.log(err);
    throw err;
  }
}

export async function checkPayment(data){
  try{
    const response = await axiosInstance.post(`${BACKEND_URL}/payment/check`, data);
    return response.data;
  }catch(err){
    console.log(err);
    throw err;
  }
}