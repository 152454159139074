import { useTranslation } from 'react-i18next';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import AppBar from '@mui/material/AppBar';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import HeaderLanguages from './HeaderLanguages';
import HeaderContainer from './HeaderContainer';
import Box from '@mui/material/Box'; // Ensure this is imported

export default function Header({ handleDrawerToggle }) {
  const { t } = useTranslation();

  return (
    <HeaderContainer>
      <AppBar
        position="fixed"
        sx={{
          display: { md: 'none' },
          backgroundImage: 'linear-gradient(to top right, #3498db, #16a085);'
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className="icon-for-drawer"
            sx={{ mr: 2, display: { md: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1, textAlign:'center', color: '#f5f5f5', textTransform: 'uppercase', fontSize: '1.5rem', display: { xs: 'none', sm: 'block' } }} mt={1}>
            {t('appbar_text_sm', { ns: 'layout' })}
          </Typography>
          {/* Centering Box */}
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', sm: 'none' }, justifyContent: 'center', alignItems: 'center' }}>
            {/* Logo */}
            <img src="/images/ars_logo_v6.png" alt="Icon" sx={{ width: 32, height: 32 }} />
            {/* Text */}
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', ml: "3px" }}>
              <Typography variant="caption" component="div" sx={{ color: '#f5f5f5', lineHeight: '1.2', fontSize: '0.8rem' }}>
                Armenian
              </Typography>
              <Typography variant="caption" component="div" sx={{ color: '#f5f5f5', lineHeight: '1.2', fontSize: '0.8rem' }}>
                Rhinoplasty
              </Typography>
              <Typography variant="caption" component="div" sx={{ color: '#f5f5f5', lineHeight: '1.2', fontSize: '0.8rem' }}>
                Society
              </Typography>
            </Box>
          </Box>
          <HeaderLanguages anchor />
        </Toolbar>
      </AppBar>
    </HeaderContainer>
  );
}

