import { Outlet } from 'react-router-dom';
import Box from '@mui/material/Box';

export default function Main(){ 
  return (
    <Box sx={{
      minHeight: 'calc(60vh - 70px)',
      bgcolor: 'rgb(240, 242, 245)',
      overflowY:'auto',
    }}> 
      <Outlet/>
    </Box>
  )
}