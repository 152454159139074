import { useState } from 'react';
import { navs } from '../routes';
import Box from '@mui/material/Box';
import SideDrawer from './Drawer';
import Header from './Header';
import Main from './Main';
import Footer from './Footer';
import GlobalAlert from './GlobalAlert';
import EventsContextProvider from '../store/EventsContext';
import ContentContextProvider from '../store/ContentContext';
import { AlertProvider } from '../store/AlertContext';


export default function Layout(){
  const [mobileDrawerState, setMobileDrawerState] = useState(false);

  const handleDrawerToggle = () => {
    setMobileDrawerState(!mobileDrawerState);
  };

  return (
    <Box>
      <AlertProvider>
      <EventsContextProvider>
      <ContentContextProvider>
        <GlobalAlert/>
        <SideDrawer mobileDrawerState={mobileDrawerState} navs={navs} handleDrawerToggle={handleDrawerToggle}/>
        <Header handleDrawerToggle={handleDrawerToggle}/>
        <Main handleDrawerToggle={handleDrawerToggle}/>
        <Footer/>
      </ContentContextProvider>
      </EventsContextProvider>
      </AlertProvider>
    </Box>
  )
}