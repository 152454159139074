import Grid from '@mui/material/Grid';
import ViewContainer from './ViewContainer'
import BreadCrumbs from './Breadcrumbs';

export default function ViewNavigationContainer({ children, path }){
  return(
    <ViewContainer>
      <Grid container sx = {{ 
        display: 'flex',  
        flexWrap: 'no-wrap', 
        flexDirection: 'row', 
        justifyContent: 'flex-start',
        marginTop: { xs: 2, md: 3 },
        marginLeft: { xs: 1, md: 5 },
      }}>
        <Grid item xs={12} md={10}>
          <BreadCrumbs path={path || null}/>
        </Grid>
        { children }
      </Grid>
    </ViewContainer>  
  )
}