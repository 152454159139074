import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import ViewNavigationContainer from "../../components/ViewNavigationContainer";
import DisplayCard from "../../components/DisplayCard";
import { useTranslation } from "react-i18next";

export default function MainRhinoplasty(){
  const { t } = useTranslation();

  return(
   <ViewNavigationContainer>
      <Grid container sx = {{ 
        display: 'flex',  
        flexWrap: 'wrap', 
        flexDirection: 'row', 
        justifyContent: 'center',
        marginTop: { xs: 2, md: 3 },
        marginBottom: 10
      }}>
        <Grid item xs={10} md={10}>
          <DisplayCard>
            <Typography textAlign='left' variant="body1" style={{ whiteSpace: 'pre-line' }} component="p">{t('main.description', { ns: 'rhinoplasty' })}</Typography>
          </DisplayCard>
        </Grid>
      </Grid>
    </ViewNavigationContainer>
  )
}