import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';

export default function SocialMediaLinks() {
  return (
    <Box>
      <Divider />
      <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        my: 1
      }}>
        <Link href="https://www.facebook.com/groups/155574788263689/?ref=share_group_link" target="_blank" rel="noopener noreferrer" sx={{ mr: 4 }}>
          <FacebookIcon sx={{ color: '#f5f5f5' }} />
        </Link>
        <Link href="https://www.instagram.com/rhinoplastysocietyofarmenia?igsh=MTV3NGY1bXVkMzNxbQ==" target="_blank" rel="noopener noreferrer">
          <InstagramIcon sx={{ color: '#f5f5f5' }} />
        </Link>
      </Box>
    </Box>
  );
}
