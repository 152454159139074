import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextInput from './TextInput';
import CustomButton from './CustomButton';
import DegreeSelect from './DegreeSelect';
import CheckBoxInput from './CheckboxInput';
import TermsModal from './TermsModal';
import PhoneInput from './PhoneInput';
import DatesPicker from './DatePicker';
import RadioGroupInput from './RadioGroupInput';
import CountrySelect from './CountrySelect';
import { Formik, Form, Field } from 'formik';
import { AlertContext } from '../store/AlertContext';
import { generateUUID } from '../utils/uuid-generator';
import { generateVisitorProfile } from '../utils/http';


export default function VisitorRegisterStep(props) {
  const { 
    profile,
    eventId,
    setOverlayState,
    prices
  } = props;

  const { t } = useTranslation();
  const { showAlert } = useContext(AlertContext);
  const [termsModalState, setTermsModalState] = useState(false);
  const [eventCost, setEventCost] = useState(0);

  useEffect(() => {
   
    if (profile && profile.practice_details) {
      estimateCost(profile.practice_details);
    }
  }, [prices]); 

  const openTermsModal = () => {
    setTermsModalState(true);
  }

  const closeTermsModal = () => {
    setTermsModalState(false);
  }

  const estimateCost = (profileType) => {
    if(!prices) return null;
    const categoryPrice = prices[profileType];
    setEventCost(categoryPrice[categoryPrice.length-1].price);
  }

  const initialValues = { 
    first_name: '',
    last_name: '',
    email: '',
    birth_date: '',
    country: '',
    degree: '',
    occupation_discipline: '',
    business_address: '',
    phone: '',
    practice_details: '',
    terms_accepted: false
  };

  if (profile) {
    initialValues.first_name = profile.first_name ?? initialValues.first_name;
    initialValues.last_name = profile.last_name ?? initialValues.last_name;
    initialValues.email = profile.email ?? initialValues.email;
    initialValues.birth_date = profile.birth_date.split('T')[0] ?? initialValues.birth_date;
    initialValues.country = profile.country ?? initialValues.country;
    initialValues.degree = profile.degree ?? initialValues.degree;
    initialValues.occupation_discipline = profile.occupation_discipline ?? initialValues.occupation_discipline;
    initialValues.business_address = profile.business_address ?? initialValues.business_address;
    initialValues.phone = profile.phone ?? initialValues.phone;
    initialValues.practice_details = profile.practice_details ?? initialValues.practice_details;
  }


  const VisitorJoinSchema = Yup.object().shape({
    first_name: Yup.string().required(t('errors.first_name_required', { ns: 'layout' })),
    last_name: Yup.string().required(t('errors.last_name_required', { ns: 'layout' })),
    email: Yup.string().matches(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/, t('errors.invalid_email', { ns: 'layout' })).required(t('errors.email_required', { ns: 'layout' })),
    birth_date: Yup.date().required(t('errors.birth_date_required', { ns: 'layout' })),
    country: Yup.string().required(t('errors.country_required', { ns: 'layout' })),
    degree: Yup.string().required(t('errors.degree_required', { ns: 'layout' })),
    phone: Yup.string().required(t('errors.phone_required', { ns: 'layout' })),
    occupation_discipline: Yup.string().required(t('errors.occupation_required', { ns: 'layout' })),
    business_address: Yup.string().required('Required'),
    practice_details: Yup.string().required('Required'),
    terms_accepted: Yup.boolean().required('Required').oneOf([true], 'You must accept the Terms and Conditions'),
  });


  //Store this function somewhere in utils
  const formHasChanges = (initialForm, formToSubmit) => {
    const { terms_accepted, ...initialValuesWithoutTerms } = initialForm;
    const { terms_accepted: termsAcceptedValue, ...valuesWithoutTerms } = formToSubmit;

    initialValuesWithoutTerms.first_name = initialValuesWithoutTerms.first_name.trim();
    initialValuesWithoutTerms.last_name = initialValuesWithoutTerms.last_name.trim();
    initialValuesWithoutTerms.email = initialValuesWithoutTerms.email.trim();
    initialValuesWithoutTerms.country = initialValuesWithoutTerms.country.trim();
    initialValuesWithoutTerms.occupation_discipline = initialValuesWithoutTerms.occupation_discipline.trim();
    initialValuesWithoutTerms.business_address = initialValuesWithoutTerms.business_address.trim();
    valuesWithoutTerms.first_name = valuesWithoutTerms.first_name.trim();
    valuesWithoutTerms.last_name = valuesWithoutTerms.last_name.trim();
    valuesWithoutTerms.email = valuesWithoutTerms.email.trim();
    valuesWithoutTerms.country = valuesWithoutTerms.country.trim();
    valuesWithoutTerms.occupation_discipline = valuesWithoutTerms.occupation_discipline.trim();
    valuesWithoutTerms.business_address = valuesWithoutTerms.business_address.trim();

    const initialStr = JSON.stringify(initialValuesWithoutTerms);
    const valuesStr = JSON.stringify(valuesWithoutTerms);

    return initialStr !== valuesStr;
  };


  const handleSubmit = async (values, { setSubmitting }) => {
    setOverlayState(true);
    let temp_id = localStorage.getItem('temp_id');
    
    const trimmedValues = Object.keys(values).reduce((acc, key) => {
      acc[key] = typeof values[key] === 'string' ? values[key].trim() : values[key];
      return acc;
    }, {});
    
    if(!temp_id || formHasChanges(initialValues, trimmedValues)){
      temp_id = generateUUID();
      localStorage.setItem('temp_id',temp_id); 
    }

    try {
      const result = await generateVisitorProfile(eventId, { generated_id: temp_id, ...trimmedValues })
      if(result.data.redirectURL){
        window.location.href=result.data.redirectURL;
      } else {
        throw new Error('PAYMENT_PROCESS_FAILED');
      }
    } catch (error) {
      console.error('Submission failed', error);
      setOverlayState(false);
      showAlert('error',t(`${error.msg}`, { ns: 'alerts' }));
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Box mt={2} px={{ xs:0, lg:6 }}>
      <TermsModal modalState={termsModalState} handleClose={closeTermsModal}/>
      <Formik
        initialValues={initialValues}
        validationSchema={VisitorJoinSchema}
        onSubmit={handleSubmit}
      >
        {({ values, isSubmitting, isValid, dirty }) => {
          
          return (
          <Form>
            <Grid item container xs={12} md={12} rowSpacing={4} columnSpacing={4} sx={{ padding:"0px 0px 0px 0px"}}>
              <Grid item xs={12} md={6} lg={4}>
                <Field as={TextInput} label={ t('first_name', { ns: 'register' } ) } name="first_name" />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Field as={TextInput} label={ t('last_name', { ns: 'register' } ) } name="last_name" />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Field as={TextInput} label={ t('email', { ns: 'register' } ) } name="email" />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Field as={CountrySelect} label={t('country_label', { ns: 'register' })} name="country" />
              </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Field as={PhoneInput} label={t('phone', { ns: 'register' })} name="phone" />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Field as={DatesPicker} label={t('birth_date', { ns: 'register' })} name="birth_date" />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Field 
                as={DegreeSelect} 
                label={t('degree.title', { ns: 'register' })} 
                name="degree" 
                options={[
                  { value: "bachelor", label: t('degree.bachelor', { ns: 'register' }) },
                  { value: "master", label: t('degree.master', { ns: 'register' }) },
                  { value: "doctorate", label: t('degree.doctorate', { ns: 'register' }) },
                  { value: "professorship", label: t('degree.professorship', { ns: 'register' }) },
                ]}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Field as={TextInput} label={t('occupation', { ns: 'register' })} name="occupation_discipline" />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Field as={TextInput} label={t('business_address', { ns: 'register' })} name="business_address" />
            </Grid>
            <Grid item container xs={12} md={12} mt={3} alignItems={'center'}>
              <Grid item xs={12} md={10} lg={4}>
                <Field 
                  as={RadioGroupInput} 
                  label={t('practice_radio_group.title', { ns: 'register' })} 
                  name="practice_details"
                  onChange={(value) => estimateCost(value)}
                  options = {[
                    { value: 'physician', label: t('practice_radio_group.physician', { ns: 'register' }) },
                    { value: 'resident', label: t('practice_radio_group.resident', { ns: 'register' }) },
                  ]}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} justifyContent="flex-start">
              <Box display="flex" alignItems="center" color="primary.main" mt={1} mb={1}>
                <Typography variant="p" ml={1}>
                {t('register_step.cost', { ns: 'events' })}: <strong>{eventCost} {t('currency_amd', { ns: 'events' })}</strong>
                </Typography>
              </Box>

            </Grid>
            <Grid item xs={12} mb={4}>
              <Typography mb={{ xs: 2, md: 1 }}>
                <Link href="#" onClick={openTermsModal} underline="hover">
                  {t('read_terms', { ns: 'register' })} 
                </Link>
              </Typography>
              <Field 
                as={CheckBoxInput} 
                label={ t('joinPage.terms', { ns: 'events' }) }
                name="terms_accepted"
                required={true}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3} lg={2} justifyContent="flex-start">
              <CustomButton 
                label={ t('joinPage.pay', { ns: 'events' }) }
                type="submit"
                disabled={!isValid || !dirty || isSubmitting}
              />
            </Grid>
            </Grid>
          </Form>
        )}}
      </Formik>
    </Box>
  );
}