import React, { createContext, useReducer } from 'react';

export const ContentContext = createContext({
  alert:{
    type: '',
    message: ''
  },
  fireAlert: (data) => {},
  clearAlert: () => {}
});

function contentReducer(state, action) {
  switch (action.type) {
    case 'FIRE_ALERT':
      return { ...state, alert: action.payload };
    case 'CLEAR_ALERT':
      return { ...state, alert: { type: '', message: '' } };
    default:
      return state;
  }
}

function ContentContextProvider({ children }) {
  const [contentState, dispatch] = useReducer(contentReducer, { alert: { type: '', message: '' } });

  function fireAlert(data) {
    dispatch({ type: 'FIRE_ALERT', payload: data });
  }

  function clearAlert() {
    dispatch({ type: 'CLEAR_ALERT' });
  }

  const value = { 
    content: contentState, 
    fireAlert,
    clearAlert
  };

  return (
    <ContentContext.Provider value={value}>
      {children}
    </ContentContext.Provider>
  );
}

export default ContentContextProvider;
