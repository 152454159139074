import Box from '@mui/material/Box';
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import ViewNavigationContainer from "../../components/ViewNavigationContainer";
import { useTranslation } from "react-i18next";
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  components: {
    MuiTypography: {
      styleOverrides: {
        subtitle1: ({ theme }) => ({
          fontSize: '0.875rem', 
          [theme.breakpoints.up('sm')]: {
            fontSize: '1rem', 
          },
          [theme.breakpoints.up('md')]: {
            fontSize: '0.9rem',
          },
          [theme.breakpoints.up('xl')]: {
            fontSize: '1rem',
          }
        }),
        body2: ({ theme }) => ({
          fontSize: '0.875rem', 
          [theme.breakpoints.up('sm')]: {
            fontSize: '1rem', 
          },
          [theme.breakpoints.up('md')]: {
            fontSize: '0.9rem',
          }
        }),
      },
    },
  }
});

export default function PresidentMessage() {
  const { t } = useTranslation();

  return (
    <ThemeProvider theme={theme}>
      <ViewNavigationContainer path='/home/message'>
        <Grid container justifyContent="center" sx={{ marginTop: { xs: 2, md: 4 }, marginBottom: 10 }}>
          <Grid item xs={10} md={11}>
            <Card sx={{ display: 'flex', flexDirection: { xs: 'column', lg: 'row' }, alignItems: 'center' }}>
              {/* <CardMedia
                component="img"
                sx={{ width: { lg: '55%' }, height: '100%', flexGrow: 1 }}
                image="/images/president_img.JPG"
                alt="President"
              /> */}
              <CardContent sx={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                <Typography textAlign='left' variant="subtitle1" style={{ whiteSpace: 'pre-line' }}>
                  {t('card_two.description', { ns: 'home' })}
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'flex-start', width: '100%', mt: 8 }}>
                  <Typography variant="body2" textAlign="left">
                    {t('card_two.remark_one', { ns: 'home' })}<br />
                    {t('card_two.remark_two', { ns: 'home' })}<br />
                    {t('card_two.remark_three', { ns: 'home' })}<br />
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </ViewNavigationContainer>
    </ThemeProvider>
  );
}
