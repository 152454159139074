import Box from '@mui/material/Box';
import Grow from '@mui/material/Grow'
import { useEffect, useState } from 'react';

export default function HeaderLogo() {
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    setChecked(true);
  },[]);

  return(
    <Grow in={checked}>
      <Box
        sx={{
          position: 'absolute',
          zIndex: 1,
          padding: '10px',
          top: { xs: 'auto', md: 0 },
          left: { xs: 0, sm: 'auto', md: 0 },
          right: { xs: 'auto', sm: 0, md: 'auto' },
          bottom: { xs: 0, md: 'auto' },
          width: { xs: '125px', sm: '145px', md: '180px', lg: '230px' },
          backgroundImage: `linear-gradient(
            to top right,
            rgba(52, 152, 219, 0.6),
            rgba(22, 160, 133, 0.6)
          )`,
        }}
      >
        <img src="/images/ars_logo.png" alt="Logo" style={{ width: '100%' }} />
      </Box>
    </Grow>
  )
}