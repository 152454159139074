import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useTranslation } from "react-i18next";

const theme = createTheme({
  palette: {
    primary: {
      main: '#1d9e9a'
    }
  },
});

export default function ViewTabs({ tabs, tabState, tabStateHandler }) {
  const { t } = useTranslation();
  const handleChange = (e, value) => {
    tabStateHandler(value);
  };

  return (
    <ThemeProvider theme={theme}>
      <Tabs
        value={tabState}
        onChange={handleChange}
        textColor="primary"
        indicatorColor="primary"
        aria-label='Events Tab'
      >
        {tabs.map((tab) => (
          <Tab key={tab.index} value={tab.index} label={ t(tab.label, { ns: 'layout' })}/>
        ))}
      </Tabs>
    </ThemeProvider>
  );
}