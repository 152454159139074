import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useField } from 'formik';

export default function TextInput({ label, type, toggleVisibility, showPassword, ...props }) {
  const [field, meta] = useField(props);
  const errorText = meta.error && meta.touched ? meta.error : '';
  
  return (
    <TextField
      fullWidth
      {...field}
      {...props}
      label={label}
      type={type}
      variant="standard"
      error={!!errorText}
      helperText={errorText}
      sx={{
        '& .MuiInput-underline:before': {
          borderBottom: '1px solid rgba(0, 0, 0, 0.42)'
        },
        '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
          borderBottom: '1px solid rgba(0, 0, 0, 0.42)'
        }
      }}
      InputProps={
        type === 'password' || showPassword
          ? {
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => toggleVisibility()}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }
          : null
      }
    />
  );
}
