import { useTranslation } from 'react-i18next';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Link from '@mui/material/Link';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';


const ConferenceDialog = ({ open, onClose, conferenceUrl }) => {
  const { t } = useTranslation();
  
  return (
    <Dialog open={open} disableScrollLock={true} onClose={onClose} aria-labelledby="conference-dialog-title">
      <DialogTitle id="conference-dialog-title">
        {t('conferenceDialog.title', { ns: 'events' })}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers sx={{ p:3 }}>
        <DialogContentText >
          {t('conferenceDialog.description', { ns: 'events' })}
        </DialogContentText>
        <Link href={conferenceUrl} target="_blank" rel="noopener noreferrer">
          {conferenceUrl}
        </Link>
      </DialogContent>
    </Dialog>
  );
};

export default ConferenceDialog;
