import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { HelmetProvider, Helmet } from 'react-helmet-async';

import './i18n'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    // <HelmetProvider>
    //   <Helmet>
    //       <title>ArmRhinolasty</title>
    //       <meta name="viewport" content="initial-scale=1, width=device-width" />
    //   </Helmet>
      <App/>
    // </HelmetProvider>
  // </React.StrictMode>
);