import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    custom: {
      main: '#3498db',
      light: '#16a085',
      dark: '#1d9d9a',
      contrastText: '#f5f5f5'
    },
  },
});

export default function CustomButton({ variant = 'contained', label, onClick, type = 'button', disabled = false, ...props }){
  return (
    <ThemeProvider theme={theme}>
      <Box>
        <Stack {...props}>
          <Button sx={{ borderRadius: 0 }} variant={variant} type={type} color="custom" size="medium" onClick={onClick} disabled={disabled}>
            {label}
          </Button>
        </Stack>
      </Box>
    </ThemeProvider>
  );
} 