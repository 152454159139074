import { MuiTelInput } from 'mui-tel-input';
import { useField } from 'formik';

export default function PhoneInput({ name, label }) {
  const [field, meta, helpers] = useField(name);

  const handleChange = (value) => {
    helpers.setValue(value);
  };

  return (
    <MuiTelInput 
      sx={{ width: '100%' }}
      value={field.value}
      name={name}
      label={label}
      onChange={handleChange}
      variant='standard'
      defaultCountry='AM'
      error={meta.touched && Boolean(meta.error)}
      helperText={meta.touched && meta.error ? meta.error : ''}
    />
  );
}
