import { useContext, useEffect, useState } from 'react';
import { Navigate, Routes, Route } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { MemberContext } from './store/MemberContext';
import { fetchVisitorProfile } from './utils/http';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Layout from './components/Layout';
import Home from './views/Home/Home';
import MainMeeting from './views/Home/MainMeeting';
import PresidentMessage from './views/Home/PresidentMessage';
import AboutUs from './views/AboutUs/AboutUs';
import Rhinoplasty from './views/Rhinoplasty/Rhinoplasty';
import MainRhinoplasty from './views/Rhinoplasty/MainRhinoplasty';
import SecondaryRhinoplasty from './views/Rhinoplasty/SecondaryRhinoplasty';
import Events from './views/Events/Events';
import OfficialJournal from './views/OfficialJournal/OfficialJournal';
import Links from './views/Links/Links';
import CheckPage from './views/Check/CheckPage';
import EventJoin from './views/Events/EventJoin';


export default function AppNavigation() {
  const [isLoading, setIsLoading] = useState(true);
  const { setVisitorProfile } = useContext(MemberContext);
  const { ready } = useTranslation();

  useEffect(() => {
    const visitorId = localStorage.getItem('temp_id');

    async function fetchVisitor(_visitorId){
      try{
        const visitorProfile = await fetchVisitorProfile(_visitorId);
        setVisitorProfile(visitorProfile);
      }catch(err){
        console.log(err);
        localStorage.removeItem('temp_id');
      }finally{
        setIsLoading(false);
      }
    }

    if(visitorId){
      fetchVisitor(visitorId);
    }else{
      setIsLoading(false);
    }
  },[]);


  if(isLoading || !ready){
    return <LoadingScreen/>;
  }

  return (
    <Routes>
      <Route path='' element={<Layout/>}>
        <Route index element={<Home/>}/>
        <Route path='ihmrs' element={<MainMeeting/>}/>
        <Route path='message' element={<PresidentMessage/>}/>
        <Route path='about-us' element={<AboutUs/>}/>
        <Route path='rhinoplasty'>
          <Route index element={<Rhinoplasty/>}/>
          <Route path='main' element={<MainRhinoplasty/>}/>
          <Route path='secondary' element={<SecondaryRhinoplasty/>}/>
        </Route>
        <Route path='events' element={<Events/>}/>
        <Route path='events/:eventId/join' element={<EventJoin/>}/>
        <Route path='official-journal' element={<OfficialJournal/>}/>
        <Route path='links' element={<Links/>}/>
        <Route path='checkPage' element={<CheckPage/>}/>
      </Route>
      <Route path='*' element={<Navigate to='/' replace/>}/>
    </Routes>
  );
}

const LoadingScreen = () => (
  <Box sx={{ 
    display: 'flex', 
    justifyContent: 'center', 
    alignItems: 'center', 
    height: '100vh'
  }}>
    <CircularProgress />
  </Box>
)