import { useContext, useEffect } from "react";
import LoadingOverlay from "../../components/LoadingOverlay";
import { ContentContext } from "../../store/ContentContext";
import { useNavigate } from 'react-router-dom';
import { checkPayment } from "../../utils/http";

export default function CheckPage(){
  const { fireAlert } = useContext(ContentContext);
  const navigate = useNavigate();

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    if(urlParams.has('orderId') && urlParams.has('paymentID')){
      const orderId = urlParams.get('orderId');
      const paymentId = urlParams.get('paymentID');
      const responseCode = urlParams.get('responseCode');
      checkRedirectedData(orderId, paymentId);
    } else {
      navigate('/', { replace: true });
    } 

    async function checkRedirectedData(orderId, paymentId){
      try{
        const response = await checkPayment({ orderId, paymentId });
        let type;
        
        switch(response.msg){
          case 'PAYMENT_SUCCESS':
            type='success';
            break;
          case 'PAYMENT_PENDING':
            type='info';
            break;
          case 'PAYMENT_DECLINED':
            type='error';
            break;
          default:
            type='error';
            break;
        }

        fireAlert({ type, message: response.msg });
      }catch(err){
        console.log(err);
        fireAlert({ type: 'error', message: 'PAYMENT_ERROR' });
      }finally{
        navigate('/', { replace: true });
      }
    }
  },[]);

  return <LoadingOverlay open={true}/>
}

