import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import CloseIcon from'@mui/icons-material/Close';
import { styled, css } from '@mui/system';
import { Modal as BaseModal } from '@mui/base/Modal';


export default function CustomModal(props) {
  const { 
    children,
    modalState, 
    handleClose,
    style
  } = props;

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={modalState}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: StyledBackdrop }}
    >
      <Fade in={modalState}>
        <ModalContent sx={{ ...defaultSyles, ...style }}>
          <Button onClick={handleClose} sx={{ position: 'absolute', right:0, top:10 }}>
            <CloseIcon />
          </Button>
          {children}
        </ModalContent>
      </Fade>
    </Modal>
  );
}

const Backdrop = forwardRef((props, ref) => {
  const { open, ...other } = props;
  return (
    <Fade in={open}>
      <div ref={ref} {...other} />
    </Fade>
  );
});

Backdrop.propTypes = {
  open: PropTypes.bool,
};

const Modal = styled(BaseModal)`
  position: fixed;
  z-index: 1300;
  inset: 0;
  display: block;
  align-items: center;
  justify-content: center;
`;

const StyledBackdrop = styled(Backdrop)`
  z-index: -1;
  position: fixed;
  inset: 0;
  background-color: rgb(0 0 0 / 0.5);
  -webkit-tap-highlight-color: transparent;
`;

const defaultSyles = {
  position: 'absolute',
  top: { xs: '50%', md: '50%'},
  left: '50%',
  transform: 'translate(-50%, -50%)',
};

const ModalContent = styled('div')(
  ({ theme }) => css`
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    text-align: start;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 8px;
    max-height: 90vh;
    overflow: auto;
    background-color: ${theme.palette.mode === 'dark' ? '#1C2025' : '#fff'};
    border-radius: 0;
    border: 1px solid #1d9e9a;
    box-shadow: 0 4px 12px
      ${theme.palette.mode === 'dark' ? 'rgb(0 0 0 / 0.5)' : 'rgb(0 0 0 / 0.2)'};
    padding: 25px 25px 24px 35px;
    color: ${theme.palette.mode === 'dark' ? '#F3F6F9' : '#1C2025'};

    & .modal-title {
      margin: 0;
      line-height: 1.5rem;
      margin-bottom: 5px;
    }

    & .modal-description {
      margin: 0;
      line-height: 1.5rem;
      font-weight: 400;
      color: ${theme.palette.mode === 'dark' ? '#B0B8C4' : '#303740'};
      margin-bottom: 4px;
    }
    @media (max-width: 600px) {
      padding: 16px;
    }
  `,
);