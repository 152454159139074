import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import useMediaQuery from '@mui/material/useMediaQuery';
import CardMedia from '@mui/material/CardMedia';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { generateEventsData } from '../utils/dateManager';
import CustomButton from './CustomButton';
import LinesEllipsis from 'react-lines-ellipsis';

const textStyle = {
  fontWeight: 400,
  letterSpacing: '0.02em',
  lineHeight: 'normal'
};

const getYoutubeEmbedUrl = (url) => {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
  const match = url.match(regExp);

  if (match && match[2].length === 11) {
    return `https://www.youtube.com/embed/${match[2]}`;
  }

  return url;
};

const EventCard = (props) => {
  const { 
    isPassed,
    title,
    startDate,
    endDate,
    description,
    coverType,
    coverUrl,
    address,
    disabled=false,
    isJoined,
    onButtonClick,
    conferenceUrl
  } = props;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { t } = useTranslation();
  const { day, month, year, weekday, time } = generateEventsData(startDate, 'Asia/Yerevan');
  let parsedEndDate;
  if(endDate){
    parsedEndDate = generateEventsData(endDate, 'Asia/Yerevan');
  }

  const finalCoverUrl = coverType === 'video' ? getYoutubeEmbedUrl(coverUrl) : coverUrl;
  return (
    <Card sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', width: '100%', height: '100%', overflow: 'hidden' }}>
      {coverType === 'video' ? (
        <Box sx={{ width: isMobile ? '100%' : '60%', height: isMobile ? '250px' : '545px' }}>
          <iframe
            width='100%'
            height='100%'
            src={finalCoverUrl}
            title={title}
            frameBorder='0'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
            allowFullScreen
          ></iframe>
        </Box>
      ) : (
        <CardMedia
          component="img"
          sx={{ width: isMobile ? '100%' : '60%', height: isMobile ? '250px' : '545px', objectFit: 'cover' }}
          image={finalCoverUrl}
          alt="Event background"
        />
      )}
      <Box sx={{ width: isMobile ? '100%' : '40%', display: 'flex', flexDirection: 'column', justifyContent: 'center', bgcolor: 'white', padding: '20px' }}>
        <Stack direction="row" justifyContent="flex-start" alignItems="flex-start">
          <Typography variant={isMobile ? 'h3' : 'h2'} component="div" gutterBottom color="text.primary" sx={{ ...textStyle, fontSize: '3.5em' }}>
            {day}
          </Typography>
          <Stack ml={1}>
            <Typography variant="subtitle1" component="div" gutterBottom color="text.secondary" sx={{ ...textStyle, fontSize: '1em' }}>
              {t(`months.${month}`, { ns: 'events' })}
            </Typography>
            <Typography variant="subtitle1" component="div" color="text.secondary" sx={{ ...textStyle, fontSize: '1em' }}>
              {year}
            </Typography>
          </Stack>
        </Stack>

        { !isMobile && <Divider sx={{ borderColor: 'rgba(0, 0, 0, 0.42)', mb: '15px', width: '30%' }} /> }

        <Typography variant="h6" gutterBottom color="text.secondary" sx={{ ...textStyle }}>
          {t(`weekdays.${weekday}`, { ns: 'events' })} {time} (GMT+4)
        </Typography>
       
        <Typography variant="subtitle1" gutterBottom color="text.secondary" sx={{ ...textStyle }}>
          {address}
        </Typography>
        <Typography variant="h5" color="text.primary" sx={{ ...textStyle, mt: 2, fontWeight: 500 }}>
          {title}
        </Typography>
        <Typography variant="body1" color="text.primary" component="div" sx={{ ...textStyle, mt: 2 }}>
          <LinesEllipsis 
              text={description}
              basedOn="words"
              maxLine='5'
              trimRight
              ellipsis="..."
            />
        </Typography>

        {!isPassed ? (
          <CustomButton 
            disabled={(isJoined && !conferenceUrl) || disabled}
            label= { isJoined && conferenceUrl ?
              t('join_online_button_label',{ ns: 'events' }).toUpperCase()
              : isJoined
                ? t('joined_button_label',{ ns: 'events' }).toUpperCase()
                : t('join_button_label',{ ns: 'events' }).toUpperCase()
            } 
            onClick={onButtonClick}
            sx={{ alignItems: { xs: 'center', sm: "flex-start" }, justifyContent:"flex-end" }}
            mt={4}
            mb={2}  
          />
        ) : null}
        
        { isMobile && endDate ? <Divider sx={{ borderColor: 'rgba(0, 0, 0, 0.42)', mt:'15px', mb: '15px', width: '100%' }} /> : null }

        {endDate && (
          <Typography variant="subtitle1" mt={2} color="text.secondary" sx={{ ...textStyle }}>
            <b>{ t('end_date_label',{ ns: 'events' })}:</b> {parsedEndDate.day}/{t(`months.${parsedEndDate.month}`, { ns: 'events' })}/{parsedEndDate.year} {parsedEndDate.time} (GMT+4)
          </Typography>)
        }
      </Box>
    </Card>
  );
};

export default EventCard;
