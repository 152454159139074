import "./index.css";
import { BrowserRouter as Router } from "react-router-dom";
import MemberContextProvider from "./store/MemberContext";
import AppNavigation from "./Navigation";

function App() {
  return (
    <>
      <MemberContextProvider>
        <Router>
          <AppNavigation/>
        </Router>
      </MemberContextProvider>
    </>
  );
}

export default App;
