import Grid from "@mui/material/Grid";
import Grow from "@mui/material/Grow";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import { useTranslation } from "react-i18next";
import ViewContainer from "../../components/ViewContainer";
import { useEffect, useState } from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";


const theme = createTheme({
  components: {
    MuiTypography: {
      styleOverrides: {
        p: ({ theme }) => ({
          fontSize: '0.8rem', 
          [theme.breakpoints.up('xs')]: {
            fontSize: '0.8rem', 
          },
          [theme.breakpoints.up('md')]: {
            fontSize: '0.8rem',
          },
          [theme.breakpoints.up('lg')]: {
            fontSize: '0.90rem',
          },
          [theme.breakpoints.up('xl')]: {
            fontSize: '1.2rem',
          }
        }),
        subtitle1: ({ theme }) => ({
          fontSize: '1.2rem', 
          [theme.breakpoints.up('xs')]: {
            fontSize: '1.2rem', 
          },
          [theme.breakpoints.up('sm')]: {
            fontSize: '1.5rem', 
          },
          [theme.breakpoints.up('md')]: {
            fontSize: '1.8rem',
          },
          [theme.breakpoints.up('lg')]: {
            fontSize: '1.8rem',
          },
          [theme.breakpoints.up('xl')]: {
            fontSize: '3rem',
          }
        }),
      },
    },
  },
});

export default function OfficialJournal(){
  const { t } = useTranslation();
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    setChecked(true);
  },[]);

  return( 
    <ThemeProvider theme={theme}>
      <ViewContainer>
        <Grid container sx = {{ 
          display: 'flex',  
          flexWrap: 'wrap', 
          flexDirection: 'row', 
          justifyContent: 'center',
          marginTop: { xs: 2, md: 5 },
          marginBottom: 10
          }} rowSpacing={5} columnSpacing={3}>
            <Grow in={checked} style={{ transitionDuration: '600ms' }}>
              <Grid item xs={12} sm={11} md={5} sx={{ display: 'flex', justifyContent: { xs: 'center', md:'flex-end'}, alignItems: 'center'}} >
                <Box sx={{ width: { xs: "70%", md: "100%", lg:"90%"} }}>
                  <img src='/images/journal.jpg' width="100%"/>
                </Box>
              </Grid>
            </Grow>
            <Grow in={checked} style={{ transitionDuration: '600ms'}}>
              <Grid item xs={12} sm={11} md={5} sx={{ display: 'flex', justifyContent: { xs: 'center', md:'flex-start'}}}>
                <Box width="60%" sx={{ marginTop: { sm: 2, md: 5 , lg: 8 }, width: { xs: "60%", md:"80%", lg: "60%" } }} >
                  <Typography variant="subtitle1" component="div" color={'#3498db'}> { t('title', { ns: 'officialJournal' }) } </Typography>
                  <Divider sx={{ marginTop: 2, marginBottom: 2 }} variant="middle"/>
                  <Typography variant="p" component="div">
                    { t('content', { ns: 'officialJournal' }) }
                  </Typography>
                </Box>
              </Grid>
            </Grow>
        </Grid>
      </ViewContainer>
    </ThemeProvider>
  )
}