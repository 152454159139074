import { Gb, Am, Ru } from 'react-flags-select';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

export default function Languages(){
  const lngs = [
    { component: <Gb fontSize="1.5rem"/>, code: 'en' },
    { component: <Ru fontSize="1.5rem"/>, code: 'ru' },
    { component: <Am fontSize="1.5rem"/>, code: 'am' }
  ];

  const { i18n } = useTranslation();

  return (
    <Box>
      <Box sx={{
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'stretch',
        width: '100%',
        mb:1
      }}>
        {lngs.map((lngItem) => (
          <Button 
            key={lngItem.code}
            disabled={i18n.resolvedLanguage === lngItem.code}
            onClick={() => i18n.changeLanguage(lngItem.code)}
          > 
            { lngItem.component }
          </Button>
        ))}
      </Box>
    </Box>
  ) 
}